<template>
    <div :class="classes"></div>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        size: {
            type: String,
            default: 'medium',
            validator: (val) => ['small', 'medium', 'large'].includes(val)
        }
    },
    computed: {
        classes() {
            return {
                [`spinner--${this.size}`]: true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;

    &--small {
        @extend .spinner;
        width: 1rem;
        height: 1rem;
    }

    &--medium {
        @extend .spinner;
        width: 1.5rem;
        height: 1.5rem;
    }

    &--large {
        @extend .spinner;
        width: 2rem;
        height: 2rem;
    }
}
</style>
