<template>
    <div class="button-wrapper">
        <Button v-bind="$attrs" fluid> </Button>
        <div class="spinner-wrapper">
            <Spinner v-show="isActive" size="medium" />
        </div>
    </div>
</template>

<script>
import Spinner from '@/components/loading-indicators/Spinner'
export default {
    name: 'ButtonWithSpinner',
    components: { Spinner },
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
    width: 100%;
    position: relative;
}

.spinner-wrapper {
    position: absolute;
    right: 15%;
    top: 25%;
}
</style>
