<template>
    <div class="login bg-light text-primary">
        <router-link to="/" class="login__close-button">
            <button class="button button-close"></button>
        </router-link>
        <img src="@/assets/logo_primary.svg" />
        <h1 class="login__title">Login</h1>
        <form id="login" class="gb-form" @submit.prevent="onSubmit">
            <fieldset class="gb-form__fieldset">
                <label for="email">Your email</label>
                <BaseInput
                    id="email"
                    v-model.trim="identifier"
                    type="email"
                    text-align="center"
                    required
                    autofocus
                />
            </fieldset>
            <fieldset class="gb-form__fieldset">
                <label for="password">Password</label>
                <PasswordInput
                    id="password"
                    v-model="password"
                    text-align="center"
                    required
                    pattern=".*"
                />
            </fieldset>
            <p v-if="!!error" class="text--red">{{ error.message }}</p>
            <router-link to="reset-password">
                <small>Forgot your password?</small>
            </router-link>
            <Button label="Sign in" type="submit" primary />
            <!-- <ButtonWithSpinner label="Sign in" type="submit" primary :is-active="isLoading" /> -->
        </form>
    </div>
</template>

<script>
import PasswordInput from '@/components/inputs/PasswordInput'
import ButtonWithSpinner from '@/components/ButtonWithSpinner'
import { IS_LOGGED_IN_QUERY } from '@/graphql/queries/local'
import logger from '@/utils/logger'

export default {
    name: 'Login',
    components: {
        PasswordInput,
        ButtonWithSpinner
    },
    inject: ['login'],
    apollo: {
        isLoggedIn: {
            query: IS_LOGGED_IN_QUERY,
            update({ isLoggedIn }) {
                if (!isLoggedIn && localStorage.getItem('profile-context')) {
                    localStorage.removeItem('profile-context')
                }
                return isLoggedIn
            }
        }
    },
    data() {
        return {
            identifier: '',
            password: '',
            error: null,
            isLoading: false
        }
    },
    methods: {
        async onSubmit() {
            try {
                this.isLoading = true

                const res = await this.login(this.identifier, this.password)
                const { errors, login } = res

                if (errors) {
                    this.error = errors[0]
                }

                if (login) {
                    logger.log('Redirecting to dashboard')
                    this.$router.push('/dashboard')
                }
            } catch (error) {
                logger.error(error)
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login {
    min-height: 100vh;
    display: grid;
    align-content: start;
    justify-items: center;
    padding: 3rem 1rem 1rem;
    text-align: center;

    &__title {
        padding: 2rem 0 1rem;
    }
    &__close-button {
        justify-self: end;
    }
    &__form {
        display: grid;
        row-gap: 1.5rem;
        width: 260px;
    }

    &__form-group {
        display: grid;
        justify-items: center;
        row-gap: 0.5rem;
    }
}
</style>
